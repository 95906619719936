<template>
  <div>
    <label class="bold-text" v-if="title">{{ title }}</label>
    <label v-else class="bold-text">Seleziona Comuni</label>
    <v-select
      multiple
      label="comune"
      :options="options"
      v-model="select"
      :selectable="(item) => limitSelect(item)"
      @search="onSearchComuni"
      @input="sendEmit"
    >
      <template slot="selected-option" slot-scope="option">
        {{ option.comune }} - {{ option.cap }} ({{ option.provincia }})
      </template>
      <template slot="option" slot-scope="option">
        {{ option.comune }} - {{ option.cap }} ({{ option.provincia }})
      </template>
      <span slot="no-options">Scrivi un nome di un comune</span>
    </v-select>
    <br />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: function () {
    return {
      tipologia: null,
      select: [],
      options: [],
      blockSize: 5,
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.select = this.selected;
      },
    },
  },
  computed: {
    ...mapGetters('agenzia', ['agenzia']),
  },
  methods: {
    limitSelect(item) {
      if (this.agenzia.limitazione_comuni && this.tipologia !== 'Lutto') this.blockSize = 1;
      else this.blockSize = 5;

      let limit = false;
      if (this.select !== null && this.select.length === this.blockSize) limit = true;

      if (!limit) return item;
    },
    sendEmit(value) {
      this.$emit('getMultiComuni', value);
    },
    onSearchComuni(search, loading) {
      if (search.length) {
        loading(true);
        axios
          .get(`/dashboard/api/v0/comuni/listfrequently?comune__icontains=${escape(search)}`)
          .then(
            (response) => {
              if (response.status === 200) {
                this.options = response.data.results;
                loading(false);
              }
            },
            (error) => {
              console.log('errore nei comuni');
              console.log(error);
            }
          );
      }
    },
  },
  mounted() {
    if (this.selected === null) {
      axios.get('/dashboard/api/v0/comuni/listfrequently').then(
        (response) => {
          if (response.status === 200) {
            this.options = response.data.results;
          }
        },
        (error) => {
          console.log('errore nei comuni');
          console.log(error);
        }
      );
    }
  },
  props: {
    selected: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
    },
    tipologia: {
      type: String,
    },
  },
  name: 'MultiComuni',
};
</script>
