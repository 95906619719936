import moment from 'moment';
import { computeDate } from './helper';

export function setData(data, obj, tipologia) {
  obj.nome_manifesto = data.nome_manifesto;
  obj.foto_manifesto = data.foto_manifesto;
  obj.comuni = data.comuni;
  obj.tipologia = data.tipologia;

  if (tipologia === 'Lutto') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.camera_ardente = data.camera_ardente;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.stanza_funeraria = data.stanza_funeraria;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
    obj.pubblicazione_solo_totem = data.pubblicazione_solo_totem;
    if (data.foto_monitor_interni !== null && data.foto_monitor_interni.length > 0)
      obj.foto_monitor_interni = data.foto_monitor_interni
        .replace('https://ucarecdn.com/', '')
        .replace('/', '');
  } else if (tipologia === 'Ottavario') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  } else if (tipologia === 'SeiMesi') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  } else if (tipologia === 'Trigesimo') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  } else if (tipologia === 'Comunicazione Lutto') {
    computeDate(data, obj);
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  } else if (tipologia === 'Ringraziamento') {
    obj.necrologi = data.necrologi;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  } else if (tipologia === 'RingraziamentoOttavario') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
  } else if (tipologia === 'RingraziamentoTrigesimo') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  } else if (tipologia === 'Partecipazione') {
    obj.necrologi = data.necrologi;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
  } else if (tipologia === 'Partecipazione Esterna') {
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
  } else if (tipologia === 'Anniversario') {
    obj.nome_luogo_rito = data.nome_chiesa;
    obj.comuni = data.comuni;
    obj.necrologi = data.necrologi;
    obj.comune_luogo_rito_riccorenza = data.comune_luogo_rito;
    obj.scelta_rito = data.scelta_rito_ricorrenza;
    obj.blocco_servizio_fiori = data.blocco_servizio_fiori;
    obj.address = data.address;
    obj.blocco_funerale = data.blocco_funerale;
    obj.pubblicazione_facebook = data.pubblicazione_facebook;
    obj.pubblicazione_facebook_automatica = data.pubblicazione_facebook_automatica;
    obj.tipologia_luogo_rito_ricorrenza_selected = data.tipologia_luogo_rito_ricorrenza;
    obj.link_web = data.link_web;
    obj.username = data.username;
    obj.password = data.password;
    obj.testo_ringraziamenti = data.testo_ringraziamenti;
  }
}

export function normalizeDataForCreate(obj) {
  let comuni = [];
  let data_rito_ricorrenza;
  let data_pubblicazione;
  let comune_luogo_rito;

  if (obj.necessita_verifica === false) obj.stato_pubblicazione = 'PUBBLICATO';

  if (obj.necessita_verifica === true) {
    obj.foto_manifesto = 'https://ucarecdn.com/51580348-ca60-48d2-8af4-c2c43d28c070';
    obj.stato_pubblicazione = 'VERIFICA';
  }

  if (obj.comuni !== null) {
    for (let comune of obj.comuni) {
      comuni.push(comune.ref_id);
    }
  }

  if (obj.comune_luogo_rito_riccorenza !== null) {
    if (obj.comune_luogo_rito_riccorenza.ref_id !== undefined) {
      comune_luogo_rito = parseInt(obj.comune_luogo_rito_riccorenza.ref_id);
    } else {
      comune_luogo_rito = parseInt(obj.comune_luogo_rito_riccorenza.id);
    }
  }

  if (obj.ora_rito_ricorrenza !== null && obj.data_rito_ricorrenza !== null) {
    obj.data_rito_ricorrenza.setHours(obj.ora_rito_ricorrenza.HH, obj.ora_rito_ricorrenza.mm);
    data_rito_ricorrenza = moment(obj.data_rito_ricorrenza).utcOffset(0, true).format();
  }

  if (
    obj.pubblicazione_immediata === true &&
    obj.data_pubblicazione !== null &&
    obj.ora_pubblicazione !== null
  ) {
    obj.data_pubblicazione.setHours(obj.ora_pubblicazione.HH, obj.ora_pubblicazione.mm);
    data_pubblicazione = moment(obj.data_pubblicazione).utcOffset(0, true).format();
  }

  return {
    nome_manifesto: obj.nome_manifesto,
    foto_manifesto: obj.foto_manifesto,
    address: obj.nome_luogo_rito,
    comuni: comuni,
    tipologia: obj.tipologia,
    giorno_ora_messa: data_rito_ricorrenza,
    nome_chiesa: obj.nome_luogo_rito,
    comune_luogo_rito: comune_luogo_rito,
    stato_pubblicazione: obj.stato_pubblicazione,
    blocco_servizio_fiori: obj.blocco_servizio_fiori,
    data_pubblicazione: data_pubblicazione,
    pubblicazione_facebook: obj.pubblicazione_facebook,
    pubblicazione_immediata: obj.pubblicazione_immediata,
    tipologia_luogo_rito_ricorrenza: obj.tipologia_luogo_rito_ricorrenza_selected,
    scelta_rito_ricorrenza: obj.scelta_rito,
    link_web: obj.link_web,
    username: obj.username,
    password: obj.password,
  };
}

export function normalizeDataForUpdate(obj) {
  let data_rito_ricorrenza;
  let data_pubblicazione;
  let comuniListId = [];

  if (obj.comuni !== null) {
    for (let comune of obj.comuni) {
      comuniListId.push(comune.ref_id);
    }
  }

  let comuneLuogoRitoId = null;
  if (obj.comune_luogo_rito_riccorenza !== null) {
    if (obj.comune_luogo_rito_riccorenza.ref_id !== undefined) {
      comuneLuogoRitoId = parseInt(obj.comune_luogo_rito_riccorenza.ref_id);
    } else {
      comuneLuogoRitoId = parseInt(obj.comune_luogo_rito_riccorenza.id);
    }
  }

  if (obj.ora_rito_ricorrenza !== null && obj.data_rito_ricorrenza !== null) {
    obj.data_rito_ricorrenza.setHours(obj.ora_rito_ricorrenza.HH, obj.ora_rito_ricorrenza.mm);
    data_rito_ricorrenza = moment(obj.data_rito_ricorrenza).utcOffset(0, true).format();
  }

  if (
    obj.pubblicazione_immediata === obj &&
    obj.data_pubblicazione !== null &&
    obj.ora_pubblicazione !== null
  ) {
    obj.data_pubblicazione.setHours(obj.ora_pubblicazione.HH, obj.ora_pubblicazione.mm);
    data_pubblicazione = moment(obj.data_pubblicazione).utcOffset(0, true).format();
  }

  return {
    nome_manifesto: obj.nome_manifesto,
    foto_manifesto: obj.foto_manifesto,
    address: obj.nome_luogo_rito,
    giorno_ora_messa: data_rito_ricorrenza,
    nome_chiesa: obj.nome_luogo_rito,
    comune_luogo_rito: comuneLuogoRitoId,
    tipologia: obj.tipologia,
    //stato_pubblicazione: this.stato_pubblicazione,
    blocco_servizio_fiori: obj.blocco_servizio_fiori,
    //data_pubblicazione: data_pubblicazione,
    pubblicazione_facebook: obj.pubblicazione_facebook,
    tipologia_luogo_rito_ricorrenza: obj.tipologia_luogo_rito_ricorrenza_selected,
    scelta_rito_ricorrenza: obj.scelta_rito,
    link_web: obj.link_web,
    username: obj.username,
    password: obj.password,
    reset_foto: true,
  };
}
