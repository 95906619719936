import {
  normalizeDataForCreate,
  normalizeDataForUpdate,
  setData,
} from '@/views/funer24/manifesti/tipologie/normalizer';

const API_CREATE_MANIFESTO = '/dashboard/api/v0/manifesti/create';
const API_UPDATE_MANIFESTO = '/dashboard/api/v0/manifesti/update/';
const API_VIEW_MANIFESTO = '/dashboard/api/v0/manifesti/get/';

export async function updateManifesto(obj) {
  try {
    return await axios.patch(API_UPDATE_MANIFESTO + obj.id, normalizeDataForUpdate(obj));
  } catch (e) {
    console.log(e);
  }
}

export async function createManifesto(obj) {
  try {
    return await axios.post(API_CREATE_MANIFESTO, normalizeDataForCreate(obj));
  } catch (e) {
    console.log(e);
  }
}

export async function getManifesto(obj) {
  try {
    const res = await axios.get(API_VIEW_MANIFESTO + obj.id);
    setData(res.data, obj, obj.tipologia);
  } catch (e) {
    console.log(e);
  }
}
