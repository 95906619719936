var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.loadedItems,"fields":_vm.fields,"table-filter":{
            external: true,
            lazy: true,
            label: 'Cerca Necrologio',
            placeholder: ' ',
          },"table-filter-value":_vm.tableFilterValue,"items-per-page":5,"active-page":1,"outlined":"","noItemsView":{
            noResults: 'Nessun risultato da filtrare disponibile',
            noItems: 'Nessun necrologio trovato',
          },"hover":"","loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event}},scopedSlots:_vm._u([{key:"fotoNecrologioCustom",fn:function({ item }){return [_c('td',[_c('CImg',{attrs:{"src":_vm.resolvePhoto(item),"shape":"rounded-circle","width":"100","height":"100","className":"mb-6"}})],1)]}},{key:"creato",fn:function({ item }){return [_c('td',[_c('h6',[_vm._v(_vm._s(_vm.resolveDatetime(item.created_at)))])])]}},{key:"aggiornato",fn:function({ item }){return [_c('td',[_c('h6',[_vm._v(_vm._s(_vm.resolveDatetime(item.updated_at)))])])]}},{key:"comuni",fn:function({ item }){return [_c('td',[_c('h6',[_vm._v(_vm._s(item.comuni.comune))])])]}},{key:"bottoneVisualizzazione",fn:function({ item }){return [_c('td',[_c('CButton',{staticClass:"m-1",attrs:{"size":_vm.sizeMd,"color":"primary"},on:{"click":function($event){return _vm.sendNecrologio(item)}}},[_vm._v(" Seleziona Necrologio ")])],1)]}}])}),_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.pages > 1),expression:"pages > 1"}],attrs:{"pages":_vm.pages,"active-page":_vm.activePage},on:{"update:activePage":function($event){_vm.activePage=$event},"update:active-page":function($event){_vm.activePage=$event}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }